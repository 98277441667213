<template>
    <v-container class="col-md-8 col-xs-12 col-sm-12">
        <v-card elevation="0">
            <v-toolbar color="primary" dark dense elevation="0">
                <span>Oversigt</span>
                <v-spacer></v-spacer>
                <v-text-field
                    label="Søg..."
                    v-model="search"
                    single-line
                    hide-details
                />
            </v-toolbar>

            <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
                single-expand
                item-key="name"
                show-expand
                class="elevation-1"
                :items-per-page="-1"
                dense

            >
                <template v-slot:item.name="{item}">
                    <router-link
                        :to="{name:'Profil',params:{id:item.id}}">
                        {{item.name}}
                    </router-link>
                </template>
                <template v-slot:item.id="{item}">
                    <span style="text-align: right">
                    {{item.completed.length}}
                    <v-icon color="green">mdi-seal</v-icon>
                    </span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td colspan="4" class="pa-0">
                        <v-data-table
                            :items="item.timeSpent"
                            :headers="[
                            {text:'Note',value:'note'},
                            {text: 'Tid', value: 'timeSpent', width: 120},
                            {text: 'Dato', value: 'taskDate', width: 120,},
                        ]"
                        >
                            <template v-slot:item.note="{item}">
                                <router-link :to="{name:'Lektion',params:{id:item.course}}">
                                    {{item.note}}
                                </router-link>
                            </template>
                            <template v-slot:item.taskDate="{item}">
                                {{formatDate(item.taskDate)}}
                            </template>
                        </v-data-table>
                    </td>
                </template>
            </v-data-table>

        </v-card>

    </v-container>
</template>

<script>
    import api from "../services/api";

    export default {
        name: "Overview",
        data() {
            return {
                search: '',
                users: [],
                headers: [
                    {text: 'Navn', value: 'name'},
                    {text: 'Tidsforbrug', value: 'tgfh'},
                    {text: '', value: 'id', width: '80px', align: 'right'},
                ]
            }
        },
        methods: {
            getUserStats() {
                api.get('/course/userstats')
                    .then(response => {
                        this.users = response.data
                    })
            },
            cCount(obj) {
                console.log(obj)
            },
            formatDate(date) {
                const [year, month, day] = date.split(' ')[0].split('-')

                return `${day}-${month}-${year}`
            }
        },
        mounted() {
            this.getUserStats()
        }
    }
</script>

<style>
</style>