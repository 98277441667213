var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"col-md-8 col-xs-12 col-sm-12"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":"","elevation":"0"}},[_c('span',[_vm._v("Oversigt")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Søg...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"single-expand":"","item-key":"name","show-expand":"","items-per-page":-1,"dense":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'Profil',params:{id:item.id}}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(item.completed.length)+" "),_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-seal")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":"4"}},[_c('v-data-table',{attrs:{"items":item.timeSpent,"headers":[
                        {text:'Note',value:'note'},
                        {text: 'Tid', value: 'timeSpent', width: 120},
                        {text: 'Dato', value: 'taskDate', width: 120,} ]},scopedSlots:_vm._u([{key:"item.note",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'Lektion',params:{id:item.course}}}},[_vm._v(" "+_vm._s(item.note)+" ")])]}},{key:"item.taskDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.taskDate))+" ")]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }